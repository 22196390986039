@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Space-Grotesk";
  src:
    local("Space Grotesk"),
    url("../public/assets/fonts/SpaceGroteskFont.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src:
    local("Inter"),
    url("../public/assets/fonts/InterFont.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Style guide - text sizes */
h1 {
  @apply font-inter font-extrabold  text-2xl lg:text-[28px];
}

h2 {
  @apply font-inter font-black  text-[21px] lg:text-[26px];
}

h3 {
  @apply font-inter font-extrabold  text-[21px] lg:text-[26px];
}

h4 {
  @apply font-sgReg font-bold  text-[21px] lg:text-[26px];
}

h5 {
  @apply font-sgReg font-bold  text-[19px] lg:text-[21px];
}

.contact-landscape-text {
  @apply font-sgReg font-bold  text-[19px] lg:text-[21px];
}

h6 {
  @apply font-sgReg font-semibold  text-[16px] lg:text-[20px];
}

.bdy-txt-1 {
  @apply font-sgReg font-bold text-[15px] lg:text-[18px] leading-snug;
}

.bdy-txt-2 {
  @apply font-sgReg font-normal text-[15px] lg:text-lg lg:leading-7;
}

.bdy-txt-3 {
  @apply font-sgReg font-normal text-[13px] lg:text-base leading-5;
}

.bdy-txt-4 {
  @apply font-sgReg font-bold text-[13px] lg:text-base leading-normal;
}

.bdy-txt-5 {
  @apply font-inter font-bold text-xs lg:text-sm;
}

.bdy-txt-6 {
  @apply font-sgReg font-normal text-[10px] md:text-[11px];
}

.dropwon-list {
  @apply font-sgReg font-normal text-sm leading-5;
}

/* Style guide - buttons */

.btn-primary {
  @apply bg-black text-white px-5 py-2 font-sgReg font-bold text-[15px] lg:text-lg leading-5 lg:leading-[23px];
  &:hover {
    @apply bg-scgreen;
  }
}

.btn-secondary {
  @apply bg-white text-black font-sgReg border-solid border-2 border-black px-5 py-2 font-semibold text-[17px] md:text-[18px] lg:text-[20px] leading-[22px] lg:leading-[25px] h-[90px];
  &:hover {
    @apply text-white border-none bg-scgreen;
  }
}

.hyperlink {
  @apply text-scgreen font-normal font-sgReg text-[13px] lg:text-base leading-5 lg:leading-6;
  &:hover {
    @apply underline cursor-pointer;
  }
}

.home-links {
  @apply text-white font-inter py-7 font-black block text-[21px] lg:text-[26px] leading-[25px] lg:leading-8;
  &:hover {
    @apply underline;
  }
}

.card-title {
  @apply font-bold font-sgReg text-[19px] xl:text-[21px] lg:leading-[30px] cursor-pointer;
  &:hover {
    @apply underline;
  }
}

.card-desc {
  @apply bdy-txt-2 lg:leading-8;
}

.document {
  @apply font-sgReg font-bold lg:text-lg flex justify-between items-center lg:leading-[23px];
  &:hover {
    @apply underline;
  }
}

/* Style guide - header */
.header-title {
  @apply font-inter font-extrabold text-[19px] lg:leading-[23px];
}

.header-sub-title {
  @apply font-inter font-extrabold text-[16px];
}

/* Style guide - footer */
.footer {
  @apply bg-black text-white font-inter;
}

.footer-copyright {
  @apply footer text-[11px] lg:leading-[13px];
}

.footer-terms {
  @apply footer-copyright underline;
  &:hover {
    @apply font-bold cursor-pointer;
  }
}

.footer-followUs {
  @apply footer text-sm font-bold lg:leading-[17px];
}

.footer-newsletter {
  @apply footer-followUs;
  &:hover {
    @apply underline cursor-pointer;
  }
}

/* Style guide - form-inputs */
.form-label {
  @apply font-inter font-extrabold lg:text-sm text-xs leading-[15px] lg:leading-[17px];
}

.dropdown-text {
  @apply font-sgReg font-bold lg:text-lg text-base leading-5 lg:leading-[22px];
}

.dropdown-menu {
  @apply font-sgReg font-normal lg:text-lg text-base leading-8;
}

.search-text {
  @apply font-sgReg font-normal lg:text-lg text-base leading-5 lg:leading-[22px];
}

/* general styles */

.title {
  @apply text-white font-inter py-7 font-bold text-[13px] md:text-[16px] leading-[25px] lg:leading-8;
}

.onLoan {
  @apply text-[#015549] font-inter font-black text-[18px];
}

.search-input::placeholder {
  @apply bdy-txt-2;
}

.btn-filter {
  @apply bg-white text-black px-5 py-2 font-sgReg font-bold text-[15px] lg:text-lg leading-5 lg:leading-[23px];
}

.custom-checkbox[type="checkbox"]:before {
  position: relative;
  display: block;
  width: 1rem;
  height: 1rem;
  border: 1px solid black;
  content: "";
  background: transparent;
}

.swiper-pagination {
  position: relative !important;
  text-align: justify !important;
  width: auto !important;
}
.swiper-pagination-bullet {
  width: 8px !important;
  height: 8px !important;
  margin: 0 8px;
}

.swiper-pagination-bullet-active {
  background-color: #000000 !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.truncate-2-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.truncate-3-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.ReactModal__Content {
  inset: 0px !important;
  width: 100% !important;
  background: rgba(0, 0, 0, 0.6) !important;
}

.ReactModal__Overlay {
  z-index: 100 !important;
}

.MuiOutlinedInput-notchedOutline {
  // inside border
  border: none !important;
}

.MuiInputLabel-shrink {
  // shrinked label
  display: none !important;
}

.cl-md ol {
  list-style-type: decimal;
  padding-left: 17px;
}
.cl-md ul {
  list-style-type: none; /* Remove default bullets */
  li {
    padding-bottom: 5px; /* Optional: adds some space between the list items */
  }
  li:before {
    content: "- "; /* Adds a dash before the list item */
    color: black; /* Optional: sets the color of the dash */
  }
}

.MuiIconButton-root:hover {
  // calendar icon
  color: black !important;
  background-color: transparent !important;
}

.collection-date .MuiIconButton-root,
.MuiIconButton-root {
  width: 30px !important;
  // opacity: 0;
}

.archive-date .MuiIconButton-root,
.MuiIconButton-root {
  width: 22px !important;
  // opacity: 0;
}

.MuiPickersYear-yearButton,
.MuiInputBase-input {
  // calendar font-family
  font-family: "Space Grotesk" !important;
  font-weight: 600 !important;
  // @apply font-sgReg font-bold !important;
}

// responsive design on tab screens
@media only screen and (device-width: 768px) and (device-height: 1024px) {
  //ipad mini
  //ipad mini

  header {
    margin-left: -15px !important;
  }

  .logo {
    width: 115px !important;
    margin-left: -5px;
  }

  .home-logo {
    width: 115px !important;
    margin-left: 8px;
  }

  .header-titles {
    right: 20px !important;
    position: absolute !important;
  }

  .sec-header {
    left: 15px !important;
  }

  .contact-details {
    top: -28%;
    position: relative;
  }

  .slider-details {
    margin-left: 2rem;
  }

  .footer-copyright {
    width: 335px !important;
  }

  .about-right {
    margin-top: -45px;
  }

  .footer-terms {
    display: block !important;
    width: 150px !important;
  }

  .home-sub-menu {
    margin-top: 37px;
  }

  .pages-sub-menu {
    margin-top: 28px;
  }

  .view-artist-btn {
    left: 25% !important;
  }

  .popup-content {
    width: 800px !important;
    height: 600px !important;
    margin-top: 20% !important;
    margin-left: -37px !important;
    padding: 30px !important;
  }

  .footer-popup {
    bottom: 70px !important;
  }

  .perspective-wrapper {
    padding-left: 25px;
  }

  .contact-us-wrapper {
    margin-bottom: 44%;
  }

  .showing-results {
    margin-left: 40px;
  }
}

@media only screen and (device-width: 820px) and (device-height: 1180px) {
  //ipad air
  //ipad air
  .logo {
    margin-left: -5px !important;
  }

  .home-logo {
    margin-left: 5px !important;
  }
  header {
    margin-left: -10px !important;
  }
  .header-titles {
    right: 40px !important;
    position: absolute !important;
  }
  .sec-header {
    left: 10px !important;
  }
  .contact-details {
    top: -30%;
    position: relative;
  }

  .slider-details {
    margin-left: 3rem;
  }

  .footer-copyright {
    width: 300px !important;
    margin-left: -15px;
  }

  .footer-terms {
    width: 145px !important;
    display: block !important;
  }

  .newsletter {
    margin-right: -10px;
  }
  .about-right {
    margin-top: -45px;
  }

  .home-sub-menu {
    margin-top: 36px;
  }

  .pages-sub-menu {
    margin-top: 28px;
  }

  .view-artist-btn {
    left: 35% !important;
  }
  .popup-content {
    height: 600px !important;
    margin-top: 35% !important;
    padding: 10px !important;
  }

  .footer-popup {
    bottom: 70px !important;
  }

  .perspective-wrapper {
    padding-left: 19px;
  }

  .contact-us-wrapper {
    margin-bottom: 74%;
  }

  .showing-results {
    margin-left: 40px;
  }

  .no-entries-collection {
    margin-bottom: 22%;
  }

  .no-entries-archive {
    margin-bottom: 32%;
  }
}

@media only screen and (device-width: 1024px) and (device-height: 1366px) {
  //ipad pro
  //ipad pro
  .logo {
    margin-left: 5px;
  }
  .header-border {
    width: 90% !important;
  }
  .contact-details {
    top: -20%;
    position: relative;
    margin-left: -45px;
  }

  .footer-copyright {
    width: 330px !important;
  }

  .footer-terms {
    width: 145px !important;
    display: block !important;
  }

  .about-right {
    margin-top: -45px;
  }

  .filter {
    flex-direction: column !important;
    margin-left: 60px !important;
  }

  .home-sub-menu {
    margin-top: 39px;
  }

  .pages-sub-menu {
    margin-top: 42px;
  }

  .view-artist-btn {
    left: 45% !important;
  }

  .popup-content {
    height: 800px !important;
    margin-top: 30% !important;
    padding: 10px !important;
  }

  .contact-wrapper {
    margin-right: 30px !important;
  }

  .footer-popup {
    bottom: 65px !important;
  }

  .contact-us-wrapper {
    margin-bottom: 65.7%;
  }

  .showing-results {
    margin-left: 47px;
  }

  .no-entries-collection,
  .no-entries-archive {
    margin-bottom: 40%;
  }
}

@media only screen and (device-width: 912px) and (device-height: 1368px) {
  //surface
  //surface
  .home-logo {
    margin-left: -15px !important;
    width: 150px !important;
  }

  .logo {
    margin-left: -25px !important;
    width: 150px !important;
  }
  header {
    margin-left: -5px !important;
  }
  .header-titles {
    right: 30px !important;
    position: absolute !important;
  }

  .sec-header {
    left: 5px !important;
  }

  .contact-details {
    top: -30%;
    position: relative;
    margin-left: -25px;
  }

  .slider-details {
    margin-left: 3rem;
  }

  .footer-copyright {
    width: 300px !important;
    margin-left: -15px;
  }

  .footer-terms {
    width: 145px !important;
    display: block !important;
  }

  .about-right {
    margin-top: -45px;
  }

  .nav-bar {
    padding: 2rem !important;
  }

  .home-sub-menu {
    margin-top: 20px;
  }

  .pages-sub-menu {
    margin-top: 36px;
  }

  .view-artist-btn {
    left: 35% !important;
  }

  .popup-content {
    width: 950px !important;
    height: 600px !important;
    margin-top: 40% !important;
    margin-left: -40px !important;
    padding: 35px !important;
  }

  .footer-popup {
    bottom: 70px !important;
  }

  .perspective-wrapper {
    padding-left: 23px;
  }

  .contact-us-wrapper {
    margin-bottom: 80%;
  }

  .showing-results {
    margin-left: 40px;
  }

  .no-entries-collection {
    margin-bottom: 30%;
  }

  .no-entries-archive {
    margin-bottom: 37%;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1285px) {
  .popup-content {
    height: 800px !important;
    margin-top: 5% !important;
    padding: 10px !important;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1620px) {
  .footer-wrapper {
    justify-content: space-around !important;
  }

  .slider-desc {
    top: 50% !important;
  }

  .header-border {
    width: 93% !important;
  }

  .header-titles {
    margin-right: 50px !important;
  }

  .num {
    margin-left: 0rem !important;
  }
}

@media only screen and (min-width: 1125px) and (min-height: 600px) {
  .slider-desc {
    top: 50% !important;
  }
}

@media screen and (device-width: 1180px) and (device-height: 820px) and (orientation: landscape) {
  .contact-details h4 {
    @apply contact-landscape-text;
  }

  .contact-details .bdy-txt-2 {
    @apply bdy-txt-3;
  }

  .contact-details {
    margin-left: -45px !important;
  }
}

@media only screen and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  .contact-details h4 {
    @apply contact-landscape-text;
  }

  .contact-details .bdy-txt-2 {
    @apply bdy-txt-3;
  }

  .contact-details {
    margin-top: -45px !important;
    margin-left: -45px !important;
  }
}

@media only screen and (device-width: 1366px) and (device-height: 1024px) and (orientation: landscape) {
  .footer-height {
    height: auto !important;
  }

  .contact-wrapper {
    margin-bottom: 5rem !important;
  }
}

@media only screen and (device-width: 1280px) and (device-height: 1248px) {
  .card-h {
    height: 460px !important;
  }

  .no-entries-collection {
    margin-bottom: 30%;
  }

  .no-entries-archive {
    margin-bottom: 35%;
  }
}

.ril__toolbar {
  background-color: rgba(0, 0, 0, 0) !important;
}
